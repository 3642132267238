import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"
import * as style from "./contact-form-v1.module.css"

const ContactFormAdsV1 = () => {
  const [verified, setVerified] = useState(false)
  const recaptchaRef = React.createRef()
  //const [buttonDisabled, setButtonDisabled] = useState(true);
  const [recap, setRecap] = useState("")

  const onChange = value => {
    console.log(value)
    setRecap(value)
    setVerified(prevVerified => !prevVerified)
    //setButtonDisabled(prevCheck => !prevCheck);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // set error classes

  const classInit = `form-control
                     mt-5
                     ${style.form_control}`

  const classError = `${classInit}
                      ${style.invalid_feedback_input}`

  // set form state

  const [submitStatus, setSubmitStatus] = useState("initial")

  // set success function

  const subSuccess = () => {
    // set small delay to allow smoother animation
    setTimeout(function () {
      setSubmitStatus("success")
    }, 1000)
  }

  // handle submission

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = data => {
    if (verified) {
      setSubmitStatus("processing")
      console.log(verified)

      var coName = data.enqCompany

      if (coName === "") {
        coName = "Not given"
      }

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "adsContact",
          enqSubject: "Ads V1 Contact",
          enqName: data.enqName,
          enqSurName: data.enqSurName,
          enqCompany: coName,
          enqEmail: data.enqEmail,
          enqTel: data.enqTel,
          enqMessage: data.enqMessage,
          enqMIscData: data.enqMIscData,
          "g-recaptcha-response": recap,
        }),
      })
        .then(() => subSuccess())
        .catch(error => setSubmitStatus("initial"))
    } else {
      console.log("Google reCaptcha not set")
    }
  }

  // generate form

  return (
    <>
      <h2
        className={
          submitStatus === "success"
            ? `${style.initial_intro_title} ${style.processing_intro_title} ${style.success_intro_title}`
            : "" || submitStatus === "processing"
            ? `${style.initial_intro_title} ${style.processing_intro_title}`
            : "" || submitStatus === "initial"
            ? `${style.initial_intro_title}`
            : `${style.initial_intro_title}`
        }
      >
        Contact us today!
      </h2>

      <form
        onSubmit={handleSubmit(onSubmit)}
        name="adsContact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="enqMIscData"
        data-netlify-recaptcha="true"
        action="/thank-you"
      >
        <div
          className={
            submitStatus === "success"
              ? `${style.initial} ${style.processing} ${style.success}`
              : "" || submitStatus === "processing"
              ? `${style.initial} ${style.processing} `
              : "" || submitStatus === "initial"
              ? style.initial
              : style.initial
          }
        >
          <div className="row">
            <div className="col-12 col-xl-6">
              <input
                {...register("enqName", { required: true })}
                type="text"
                placeholder="Your First Name*"
                aria-label=""
                className={errors.enqName ? `${classError}` : `${classInit}`}
              />
              {errors.enqName && (
                <span
                  className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is required
                </span>
              )}
            </div>
            <div className="col-12 col-xl-6">
              <input
                {...register("enqSurName", { required: true })}
                type="text"
                placeholder="Your Second Name*"
                aria-label=""
                className={errors.enqSurName ? `${classError}` : `${classInit}`}
              />
              {errors.enqSurName && (
                <span
                  className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <input
                {...register("enqCompany", { required: false })}
                type="text"
                placeholder="Company"
                aria-label=""
                className={classInit}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <input
                {...register("enqEmail", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
                type="text"
                placeholder="Email Address*"
                aria-label=""
                className={errors.enqEmail ? `${classError}` : `${classInit}`}
              />
              {errors.enqEmail && (
                <span
                  className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is requires a valid email address
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                {...register("enqTel", { required: true })}
                type="text"
                placeholder="Telephone Number*"
                aria-label=""
                className={errors.enqTel ? `${classError}` : `${classInit}`}
              />
              {errors.enqTel && (
                <span
                  className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea
                {...register("enqMessage", { required: true })}
                rows="4"
                placeholder="How can we help*"
                aria-label=""
                className={errors.enqMessage ? `${classError}` : `${classInit}`}
              />
              {errors.enqMessage && (
                <span
                  className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                >
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div className="d-none">
            <input {...register("enqMIscData", { required: false })} />
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                size="normal"
                onChange={onChange}
              />
              {!verified &&
                (errors.enqSubject ||
                  errors.enqMessage ||
                  errors.enqTel ||
                  errors.enqEmail ||
                  errors.enqSurName ||
                  errors.enqName) && (
                  <span
                    className={`${style.invalid_feedback} position-absolute d-block pt-1 form-text`}
                  >
                    Please confirm you are not a robot.
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button
              type="submit"
              id="adsContactForm"
              className={
                submitStatus === "success"
                  ? `${style.mfSub} ${style.processingBtn}  ${style.successBtn}`
                  : "" || submitStatus === "processing"
                  ? `${style.mfSub} ${style.processingBtn}`
                  : "" || submitStatus === "initial"
                  ? `${style.mfSub} ${style.send_icon} mt-5`
                  : `${style.mfSub} ${style.send_icon} mt-5`
              }
            >
              {submitStatus === "success" ? (
                <span>
                  <img
                    src="/assets/img/icon_success.svg"
                    alt=""
                    style={{ width: "32px" }}
                  />
                </span>
              ) : "" || submitStatus === "processing" ? (
                <span>
                  <img
                    src="/assets/img/submit_black.svg"
                    alt=""
                    style={{ width: "24px" }}
                  />
                </span>
              ) : "" || submitStatus === "initial" ? (
                <span>
                  <img
                    src="/assets/img/submit_black.svg"
                    alt=""
                    style={{ width: "24px", marginRight: "10px" }}
                  />{" "}
                  Send message
                </span>
              ) : (
                <span>
                  <img
                    src="/assets/img/submit_black.svg"
                    alt=""
                    style={{ width: "24px", marginRight: "10px" }}
                  />{" "}
                  Send message
                </span>
              )}
            </button>

            <div
              className={
                submitStatus === "success"
                  ? `${style.success_message_init} ${style.success_message} text-center`
                  : `${style.success_message_init}`
              }
            >
              <h3 className="text-center mt-4 ga_form_submit">Message sent</h3>
              <p className="text-center mb-4">
                Thank you for contacting Waracle.
                <br />A member of our team will be in touch soon.
                <br />
                <br />
                In the meantime, why not explore our{" "}
                <Link to="/insights/" title="Insights">
                  innovative insights
                </Link>{" "}
                or check out some of our{" "}
                <Link to="/our-work/" title="Our Work">
                  collaborative projects
                </Link>
                .
              </p>
              <Link
                to="/"
                title="Back to contact form"
                className="ani_grey_arrow"
                style={{ marginLeft: "-40px" }}
              >
                Back to home page
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div
              className={
                submitStatus === "success"
                  ? `${style.ts_cs} ${style.initial_tc} ${style.processing_tc} ${style.success} mt-4`
                  : "" || submitStatus === "processing"
                  ? `${style.ts_cs} ${style.initial_tc} ${style.processing_tc}  mt-4`
                  : "" || submitStatus === "initial"
                  ? `${style.ts_cs} ${style.initial_tc} mt-4`
                  : `${style.ts_cs} ${style.initial_tc} mt-4`
              }
            >
              By submitting this form you agree to the storing and processing of
              your personal data by Waracle as described in the Project enquiry
              section of the{" "}
              <a
                href="/privacy-policy/"
                target="_blank"
                style={{ zIndex: "9999", position: "relative" }}
              >
                {" "}
                Privacy policy
              </a>
              .
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactFormAdsV1
