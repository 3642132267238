import React from "react"
import { isSafariLess16 } from "../utils.js"

const HeroSimpleLeft = ({ pagedata }) => {
  let title = pagedata.heroblocksimpleleftTitle || "Title..."
  let intro = pagedata.heroblocksimpleleftIntroText || "Some intro text..."

  const isSafariV16 = isSafariLess16()

  return (
    <div className="hero-small d-flex align-items-center darkgrey_bg">
      <div className="container">
        <div id="hide-row-when-nav-open" className="row">
          <div className="col-12 color-white animated-headline">
            <h2 className="h4 mb-3 header-fade-in">{title}</h2>
            <div className="animated-headline-wrap">
              <h1 className="new_style">
                <span
                  className={`new_style_addit ${
                    isSafariV16 ? "safari_16" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: intro }}
                />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSimpleLeft
