import React from "react"
import { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import Layout from "./Layout"
import HeroSimpleLeft from "../components/shared/hero-simple-left"
import ContactFormAdsV1 from "../components/ad-pages/contact-form-v1"
import OurStudios from "../components/contact-us-page/our-studios"
import { seoHead } from "../components/utils.js"
import { useStickyBox } from "react-sticky-box"
import * as style from "./page-template-targeted-lp-one.module.css"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfHeroBlockSimpleLeft {
          heroblocksimpleleftIntroText
          heroblocksimpleleftTitle
        }

        cfAdsContentGroup {
          adsLpBlockA
        }
      }
    }
  }
`
const PageTemplateAdsVOne = ({ data }) => {
  const page = data.wpgraphql.page
  const stickyRef = useStickyBox({ offsetTop: 20, offsetBottom: 20 })

  const [screenSize, setScreenSize] = useState("mobile")

  const hasWindow = typeof window !== "undefined"

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    return {
      width,
    }
  }

  useEffect(() => {
    let theScreenWidth = getWindowDimensions()
    if (theScreenWidth.width > 1199) {
      setScreenSize("desktop")
    } else {
      setScreenSize("mobile")
    }
  })

  return (
    <>
      <Layout>
        <HeroSimpleLeft pagedata={page.cfHeroBlockSimpleLeft} />

        <div className="container">
          <div className="row mt-5 justify-content-between align-items-xl-start">
            <main
              className={`col-12 col-xl-7 ${style.content_container}`}
              dangerouslySetInnerHTML={{
                __html: page.cfAdsContentGroup.adsLpBlockA,
              }}
            />

            {screenSize === "desktop" && (
              <aside ref={stickyRef} className="col-12 col-xl-4 pt-5 pt-xl-0">
                <ContactFormAdsV1 pagedata={page} />
              </aside>
            )}

            {screenSize === "mobile" && (
              <aside className="col-12 col-xl-4 pt-5 pt-xl-0">
                <ContactFormAdsV1 pagedata={page} />
              </aside>
            )}
          </div>
        </div>

        <ParallaxProvider>
          <OurStudios pagedata={page} />
        </ParallaxProvider>
      </Layout>
    </>
  )
}

export default PageTemplateAdsVOne

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
