import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"

const OurStudios = () => {
  const boxRef = useRef()

  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 600)
    setAniBoxEnd(boxRef.current.offsetTop - 300)
  }

  useEffect(() => {
    getPosition()
  }, [])

  return (
    <>
      <div className="container mt-5 mb-6" ref={boxRef}>
        <div className="row pt-5">
          <div className="col-12 pt-5">
            <h2>Our Studios</h2>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <div
              className="bg_image color-white ani_show_content text_center"
              style={{
                backgroundImage:
                  "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/w_550/assets/dev/london_studios_aaflae.jpg)",
              }}
            >
              <div className="col-12 p-lg-4 p-3 z_index_top center_absolute add_transition">
                <h2 className="h3">London</h2>
                <div className="show-more">
                  <p>
                    New Broad Street House,
                    <br />
                    35 New Broad St,
                    <br />
                    London, EC2M 1NH
                  </p>
                  <Link
                    to="/contact/london/"
                    title="Learn More"
                    className="ani-underline color-white"
                  >
                    View details
                  </Link>
                </div>
              </div>
              <div className="dark_image_overly"></div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <Parallax
              speed={2.5}
              translateY={[10, 0]}
              startScroll={aniBoxStart}
              endScroll={aniBoxEnd}
            >
              <div
                className="bg_image color-white ani_show_content text_center"
                style={{
                  backgroundImage:
                    "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/w_550/assets/dev/dundee_studios_v8oczd.jpg)",
                }}
              >
                <div className="col-12 p-lg-4 p-3 z_index_top center_absolute add_transition">
                  <h2 className="h3">Dundee</h2>
                  <div className="show-more">
                    <p>
                      The Flour Mill,
                      <br />
                      34 Commercial St,
                      <br />
                      Dundee, DD1 3EJ
                    </p>
                    <Link
                      to="/contact/dundee/"
                      title="Learn More"
                      className="ani-underline color-white"
                    >
                      View details
                    </Link>
                  </div>
                </div>
                <div className="dark_image_overly"></div>
              </div>
            </Parallax>
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <div
              className="bg_image color-white ani_show_content text_center"
              style={{
                backgroundImage:
                  "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/w_550/assets/dev/edinburgh_studios_cc0kqu.jpg)",
              }}
            >
              <div className="col-12 p-lg-4 p-3 z_index_top center_absolute add_transition">
                <h2 className="h3">Edinburgh</h2>
                <div className="show-more">
                  <p>
                    1 Lochrin Square,
                    <br />
                    92-98 Fountainbridge,
                    <br />
                    Edinburgh, EH3 9QA
                  </p>
                  <Link
                    to="/contact/edinburgh/"
                    title="Learn More"
                    className="ani-underline color-white"
                  >
                    View details
                  </Link>
                </div>
              </div>
              <div className="dark_image_overly"></div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <Parallax
              speed={2.5}
              translateY={[10, 0]}
              startScroll={aniBoxStart}
              endScroll={aniBoxEnd}
            >
              <div
                className="bg_image color-white ani_show_content text_center"
                style={{
                  backgroundImage:
                    "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/w_550/assets/dev/glasgow_studios_kufhqo.jpg)",
                }}
              >
                <div className="col-12 p-lg-4 p-3 z_index_top center_absolute add_transition">
                  <h2 className="h3">Glasgow</h2>
                  <div className="show-more">
                    <p>
                      Wasps South Block Studios,
                      <br />
                      58-60 Osborne St,
                      <br />
                      Glasgow, G1 5QH
                    </p>
                    <Link
                      to="/contact/glasgow/"
                      title="Learn More"
                      className="ani-underline color-white"
                    >
                      View details
                    </Link>
                  </div>
                </div>
                <div className="dark_image_overly"></div>
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurStudios
